module.exports = {
	init: function(){
	},
	prodSlider: function (item) {
		if($(item).length===0 ) return false;
		$(item).flexslider({
			animation: 'fade',
			direction: 'horizontal',
			directionNav: true,
			controlNav: false,
			animationLoop: true,
			slideshow: false,
			animationSpeed: 400,
			touch: true,
			after: function(slider){
				siteGlobal.lazyLoad();
			}
		});
	},
	pageGal: function (item) {
		if($(item).length===0 ) return false;
		$(item).flexslider({
			animation: 'slide',
			direction: 'horizontal',
			directionNav: false,
			controlNav: true,
			animationLoop: true,
			touch: true,
			slideshow: true,
			slideshowSpeed: 4500,
			animationSpeed: 500,
			after: function(slider){
				siteGlobal.lazyLoad();
			}
		});
	}
}