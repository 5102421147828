var page_loaded = 1;
global.page_loaded = page_loaded;

var flexsliderLoad = require('flexslider'),
    flexslider = require('../modules/slider');

page = {
	load: function(){
		this.flexslider();
		this.smoothScroll();
		this.selectTab();
	},
	flexslider: function(){
		var slider = $('.postSlider');
	    slider.each(function() {
	      flexslider.prodSlider(slider);
	    });

	    var pageGallery = $('.pageIntro__gal');
	    pageGallery.each(function() {
	      flexslider.pageGal(pageGallery);
	    });

	    var aziendaGal = $('.aziendaLogo__gal');
	    aziendaGal.each(function() {
	      flexslider.pageGal(aziendaGal);
	    });
	},
	smoothScroll: function(){
		$('.corsoIntro__button').on('click', function(){
			event.preventDefault();
		    $('html, body').animate({
		        scrollTop: $( $.attr(this, 'href') ).offset().top - 100
		    }, 500);
		})
	},
	selectTab: function(){
		$('.prodSheet__tabs > li').on('click', function(){
			var tab_data = $(this).data('tab');

			$('.prodSheet__tabs > li').removeClass('active');
			$('.prodSheet__text').fadeOut(150);

			$(this).addClass('active');
			setTimeout(function(){
				$('[data-text="'+tab_data+'"]').fadeIn(150)
			}, 100)

		})
	}
}


$(window).on('load', function(){
	page.load();
})